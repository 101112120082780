window.pmatrix.ui.spinner = {};

(function () {
  // Loading spinners
  this.spinners = {
    dark: {
      sequence: ['#FE9B00', '#47A7EB', '#155577'],
      background: '#1B353D',
    },
    white: {
      sequence: ['#FE9B00', '#AEBED5', '#4F5B6F'],
      background: '#FFFFFF',
    },
    standard: {
      sequence: ['#0075C4', '#4D9FD6', '#FE9B00'],
      background: '#F6F6F6',
    },
  };
  this.theme_spinners = {
    dark: this.spinners.dark,
    standard: this.spinners.standard,
    transparent: this.spinners.dark,
    denim: this.spinners.dark,
    summer_sky: this.spinners.dark,
    deep_sea: this.spinners.dark,
    northern_lights: this.spinners.dark,
    morning_fog: this.spinners.dark,
    star_dust: this.spinners.dark,
    tranquil_lake: this.spinners.dark,
    white: this.spinners.white,
  };

  /**
   * SpinnerAnimation class
   */
  this.SpinnerAnimation = function (stageElement, spinnerData, options) {
    this.stageElement = stageElement;

    this.colors = spinnerData.sequence;
    this.centerFillColor = spinnerData.background;

    // Create canvas
    var size = (options && options.size) || 45;
    var cssPosition = (options && options.position) || 'absolute';
    this.canvas = document.createElement('canvas');
    $(this.canvas)
      .attr({
        width: size,
        height: size,
      })
      .css({
        position: cssPosition,
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      });

    // Append to stage
    $(stageElement).append(this.canvas);

    /**
     * Configure animation
     */

    // Milliseconds to complete one revolution
    this.rotateTime = 1200;

    // Frame rate
    this.fps = 30;

    // Start position
    this.startAngle = (Math.PI * 3) / 2; // top

    // Circle radii
    this.outsideRadius = 16;
    this.insideRadius = 11;

    /**
     * Private vars
     */
    this.ctx;
    this.arcPosition = 0;
    this.timeout = 1000 / this.fps; // calculated timeout using frame rate
    this.framesPerRotate = this.rotateTime / this.timeout;
    this.timeout = Math.floor(this.timeout);
    this.arcIncrement = (2 * Math.PI) / this.framesPerRotate; // arc increment per frame; set speed with this
    this.topColorIndex = 0;
    this.bottomColorIndex = this.colors.length - 1;
    this.frame = 0;

    if (typeof options != 'undefined') {
      if (typeof options.outside_radius != 'undefined') {
        this.outsideRadius = options.outside_radius;
      }
      if (typeof options.inside_radius != 'undefined') {
        this.insideRadius = options.inside_radius;
      }

      // If only the size parameter is specified; choose appropriate radii
      if (
        typeof options.inside_radius == 'undefined' &&
        typeof options.outside_radius == 'undefined' &&
        typeof options.size != 'undefined'
      ) {
        var r = this.insideRadius / this.outsideRadius;
        this.outsideRadius = options.size / 2;
        this.insideRadius = this.outsideRadius * r;
      }
    }

    // Center of circle
    this.centerX = this.outsideRadius;
    this.centerY = this.outsideRadius;

    return;
  };
  this.SpinnerAnimation.prototype.firstDraw = function () {
    // Get drawing contexts
    this.ctx = this.canvas.getContext('2d');

    // Draw first circles
    this.drawBottomCircle();
    this.drawCenterCircle();

    // Play the first frame
    this.draw();

    return;
  };
  this.SpinnerAnimation.prototype.drawBottomCircle = function () {
    // Draw the bottom circle
    this.ctx.clearRect(0, 0, this.outsideRadius * 2, this.outsideRadius * 2);
    this.ctx.fillStyle = this.colors[this.bottomColorIndex];
    this.ctx.beginPath();
    this.ctx.arc(
      this.centerX,
      this.centerY,
      this.outsideRadius,
      0,
      2 * Math.PI,
      false
    );
    this.ctx.fill();
  };
  this.SpinnerAnimation.prototype.drawCenterCircle = function () {
    // Draw the center circle on top of everything
    this.ctx.fillStyle = this.centerFillColor;
    this.ctx.beginPath();
    this.ctx.arc(
      this.centerX,
      this.centerY,
      this.insideRadius,
      0,
      2 * Math.PI,
      false
    );
    this.ctx.fill();
  };
  this.SpinnerAnimation.prototype.draw = function () {
    this.interval = null;
    this.drawBottomCircle();

    // Increment arc position and frame number
    this.arcPosition += this.arcIncrement;
    this.frame++;

    // Calculate the end angle for this frame
    var endAngle = this.startAngle + this.arcPosition;

    // Draw the top circle
    this.ctx.fillStyle = this.colors[this.topColorIndex];
    this.ctx.beginPath();

    this.ctx.arc(
      this.centerX,
      this.centerY,
      this.outsideRadius,
      this.startAngle,
      endAngle,
      false
    );
    this.ctx.arc(
      this.centerX,
      this.centerY,
      this.insideRadius,
      endAngle,
      this.startAngle,
      true
    );
    this.ctx.fill();

    // Check for end of rotation sequence
    if (this.frame >= this.framesPerRotate) {
      // Reset frame number & arc position
      this.frame = 0;
      this.arcPosition = 0;

      // Increment color indicies
      this.topColorIndex++;
      if (this.topColorIndex == this.colors.length) {
        this.topColorIndex = 0;
      }
      this.bottomColorIndex++;
      if (this.bottomColorIndex == this.colors.length) {
        this.bottomColorIndex = 0;
      }
    }
    this.drawCenterCircle();
    if (this.running) {
      var me = this;
      this.interval = setTimeout(function () {
        me.draw();
      }, this.timeout);
    }
    return;
  };
  this.SpinnerAnimation.prototype.start = function () {
    // If supported
    if (this.canvas.getContext) {
      this.running = true;
      // Execute first frame now
      this.firstDraw();
      // Start loop
      var me = this;
      this.interval = setTimeout(function () {
        me.draw();
      }, this.timeout);
    }
  };
  this.SpinnerAnimation.prototype.destroy = function () {
    this.running = false;

    if (this.interval) {
      // Stop animation
      clearTimeout(this.interval);
    }

    // Remove canvas from DOM
    $(this.canvas).remove();
    return;
  };

  /**
   * LoadingSpinnerObject class
   */
  this.LoadingSpinnerObject = function () {
    this.stage = null;
    this.stageElement = null;
  };

  this.LoadingSpinnerObject.prototype.create = function (
    element,
    fadeSpeed,
    options
  ) {
    options = options || {};

    this.stageElement = element;
    if (typeof fadeSpeed === 'undefined') fadeSpeed = 100;

    var theme = window.themeName || 'standard';
    var spinnerData = window.pmatrix.ui.spinner.theme_spinners[theme];

    this.stage = new window.pmatrix.ui.spinner.SpinnerAnimation(
      this.stageElement,
      spinnerData,
      options
    );
    $(this.stageElement).fadeIn(fadeSpeed);
    this.stage.start();
    return;
  };

  this.LoadingSpinnerObject.prototype.destroy = function (fadeSpeed) {
    if (typeof fadeSpeed == 'undefined') {
      fadeSpeed = 100;
    }
    this.stage.destroy();
    delete this.stage;

    $(this.stageElement).fadeOut(fadeSpeed);
    delete this.stageElement;
  };

  /**
   * LoadingSpinnerDictionary class
   */
  this.LoadingSpinnerDictionary = function () {
    this.elements = new Array();
  };

  this.LoadingSpinnerDictionary.prototype.create = function (element, options) {
    if (typeof options == 'undefined') {
      options = {};
    }

    // Search for element in array
    var obj = null;
    var i;

    var domelement;
    if (element.get) {
      domelement = element.get(0);
    } else {
      domelement = element;
    }

    for (i in this.elements) {
      if (this.elements[i].element == domelement) {
        obj = this.elements[i];
        break;
      }
    }

    // If spinner doesn't already exist
    if (obj == null) {
      // Create a new loading spinner object
      var lso = new window.pmatrix.ui.spinner.LoadingSpinnerObject();

      // Start spinner
      lso.create(element, 100, options);

      // Save element and loading spinner object into array
      var obj = {
        spinner: lso,
        element: domelement,
      };
      this.elements.push(obj);
    }
    return;
  };

  this.LoadingSpinnerDictionary.prototype.destroy = function (element) {
    // Search for element in array
    var obj = null;
    var i;
    var domelement;
    if (element.get) {
      domelement = element.get(0);
    } else {
      domelement = element;
    }
    for (i in this.elements) {
      if (this.elements[i].element == domelement) {
        obj = this.elements[i];
        break;
      }
    }
    // If found
    if (obj) {
      // Destroy spinner
      obj.spinner.destroy();
      // Delete from array
      delete this.elements[i];
    }
    return;
  };

  /**
   * Static
   */

  this.st = {
    loadingSpinner: new window.pmatrix.ui.spinner.LoadingSpinnerDictionary(),
  };
}).call(window.pmatrix.ui.spinner);

/**
 * Public interfaces
 */

/**
 * options parameter
 * -----------------
 *  options.outside_radius - Outside radius of spinner animation.
 *  options.inside_radius - Inside radius of spinner animation.
 *  options.size - Width of animation container. If specified without specifying
 *                 outside_radius or inside_radius, the spinner automatically selects
 *                 radii to fill the container.
 *  options.position - A string value for the css attribute `position`; default is absolute
 */

// Use these global functions directly, or...
window.start_loading_spinner = function (element, options) {
  var spinner_img = $('<div/>', { class: 'loading_spinner_image' });
  $(element).find('.loading_spinner_image').remove();
  $(element).append(spinner_img).show();

  /* Deprecated for simple gif spinner image
  pmatrix.ui.spinner.st.loadingSpinner.destroy( element ); // stop it first if it is already running
  pmatrix.ui.spinner.st.loadingSpinner.create( element, options );
  */
};
window.stop_loading_spinner = function (element) {
  $(element).hide().find('.loading_spinner_image').remove();

  /* Deprecated for simple gif spinner image
  pmatrix.ui.spinner.st.loadingSpinner.destroy( element );
  */
};

// ...or use these nice, static functions in the pmatrix.ui.spinner namespace (they do the same thing and can be mixed)
// i.e.: window.start_loading_spinner(elem) == pmatrix.ui.spinner.start(elem)
//       window.stop_loading_spinner(elem)  == pmatrix.ui.spinner.stop(elem)
/* Deprecated for simple gif spinner image
(function(){
  this.start = function( element, options ) {
    pmatrix.ui.spinner.st.loadingSpinner.create( element, options );
  }
  this.stop = function( element ) {
    pmatrix.ui.spinner.st.loadingSpinner.destroy( element );
  }
}).call( pmatrix.ui.spinner );
*/
