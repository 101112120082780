/** Property Matrix ui lightbox namespace */
if (typeof window.pmatrix.ui.lightbox == 'undefined') {
  window.pmatrix.ui.lightbox = {};
}
window.current_lightbox = null;
window.current_lightboxes = new Array();
(function () {
  /**
   * Lightbox class
   */
  var min_window_height = 501;
  var min_window_width = 1024;
  this.LightboxObject = function () {
    this.height = 0;
    this.width = 0;
    this.id = null;
    this.sync_input_visibility = false;
    this.use_default_drop_downs = false;
    this.theme = 'standard';
  };
  this.LightboxObject.prototype.create = function (
    id,
    height,
    width,
    styled_lightbox,
    sync_input_visibility,
    use_default_drop_downs
  ) {
    this.id = id;
    this.height = height;
    this.width = width;
    this.styled_lightbox = styled_lightbox;
    this.sync_input_visibility = sync_input_visibility;
    this.use_default_drop_downs = use_default_drop_downs;
    if (window.themeName) {
      this.theme = window.themeName;
    }
    // Remove this if statement once flexLabel is used for all lightboxes
    if (this.id != 'building') {
      this.upgrade_text_fields(id);
      this.upgrade_textareas(id);
      if (!this.use_default_drop_downs) {
        this.upgrade_drop_downs(id);
      }
    }
    this.position_Box();
    this.submitButton();
    this.buttonClicks();
  };
  this.LightboxObject.prototype.get_height = function () {
    return this.height;
  };
  this.LightboxObject.prototype.get_width = function () {
    return this.width;
  };
  this.LightboxObject.prototype.position_Box = function () {
    // Positions lightbox on screen and shifts focus to it
    var id = this.id;
    var height = this.height;
    var width = this.width;
    var window_height = $(window).height();
    var window_width = $(window).width();
    if (height > window_height - 100) {
      height = window_height - 100;
      height = Math.max(height, min_window_height - 100);
    }
    if (width > window_width - 100) {
      width = window_width - 100;
      width = Math.max(width, min_window_width - 100);
    }
    $('#' + id + '-div')
      .height(height + 100)
      .width(width + 100)
      .offset({
        left: Math.max((window_width - $('#' + id + '-div').width()) / 2, -30),
        top:
          $('#' + id + '-div').height() > window_height
            ? $(window).scrollTop() + 10
            : Math.max(
                (Math.min(window_height, 1000) -
                  $('#' + id + '-div').height()) /
                  2 +
                  $(document).scrollTop(),
                $(document).scrollTop() - 30
              ),
      });
    // Do not focus on fields that show popups
    $('#' + id + '-div')
      .find("input:visible:not('.default, .hasDatepicker')")
      .first()
      .focus();
  };
  this.LightboxObject.prototype.resize_box = function (_height, _width) {
    this.height = _height;
    this.width = _width;
    this.position_Box();
  };
  this.LightboxObject.prototype.upgrade_text_fields = function (resource) {
    var sync_input_visibility = this.sync_input_visibility;
    // Adds the text fields
    if (this.styled_lightbox) {
      var wrapper = $('<p>').addClass('form-control-static');
      $.each(
        $(
          'input:text, input[type=number], input[type=password]',
          '#' + resource + '-div'
        ).not('.form-control-static *'),
        function (i, fieldin) {
          $(fieldin).addClass('form-control');
          var $new_el = wrapper
            .clone()
            .insertAfter($(fieldin))
            .append($(fieldin));
          if (sync_input_visibility && $(fieldin).is(':hidden')) $new_el.hide();
        }
      );
    } else {
      wrapper = $('<div>').addClass('text_enclosure');
      $.each(
        $(
          'input:text, input[type=number], input[type=password]',
          '#' + resource + '-div'
        ).not('.text_enclosure *'),
        function (i, fieldin) {
          var $new_el = wrapper
            .clone()
            .insertAfter($(fieldin))
            .append($(fieldin));
          if (sync_input_visibility && $(fieldin).is(':hidden')) $new_el.hide();
        }
      );
    }
  };
  this.LightboxObject.prototype.upgrade_textareas = function (resource) {
    // Adds the textareas
    var theme = this.theme;
    var sync_input_visibility = this.sync_input_visibility;
    var wrapper = $('<div>')
      .addClass('textarea_enclosure')
      .append(
        $(
          '<img alt="Large_field" src= /assets/slice_assets/' +
            theme +
            '/shared/light_box/large_field.png />'
        ).addClass('textarea_bg')
      );
    $.each(
      $('#' + resource + '-div textarea').not('.textarea_enclosure *'),
      function (i, fieldin) {
        var $new_el = wrapper
          .clone()
          .insertAfter($(fieldin))
          .append($(fieldin));
        if (sync_input_visibility && $(fieldin).is(':hidden')) $new_el.hide();
      }
    );
  };
  this.LightboxObject.prototype.upgrade_drop_downs = function (resource) {
    // Adds the dropdowns
    var sync_input_visibility = this.sync_input_visibility;
    if (this.styled_lightbox) {
      $.each(
        $('#' + resource + '-div select').not('.drop_down *, .chosen_select'),
        function (i, fieldin) {
          $(fieldin).addClass('select2-arrow');
        }
      );
    } else {
      var wrapper = $('<div>')
        .addClass('drop_down')
        .append($('<div>').addClass('bg_left'))
        .append($('<div>').addClass('bg_right'))
        .append($('<div>').addClass('bg_tile'));
      $.each(
        $('#' + resource + '-div select').not('.drop_down *, .chosen_select'),
        function (i, fieldin) {
          var $new_el = wrapper
            .clone()
            .insertAfter($(fieldin))
            .append($(fieldin));
          if (sync_input_visibility && $(fieldin).is(':hidden')) $new_el.hide();
        }
      );
    }
  };
  this.LightboxObject.prototype.update_bg_tiles = function (resource) {
    $.each($('.drop_down'), function () {
      $(this).find('.bg_tile').text($(this).find('option:selected').text());
    });
  };
  this.LightboxObject.prototype.submitButton = function () {
    // When submit button is clicked
    var spinner_elem;
    var id = this.id;
    $('#' + id + '-div').on(
      'click',
      'div.popup_submit_button:not(.disabled, .override_submit)',
      function () {
        window.start_loading_spinner($('.loading_spinner').last());
        spinner_elem = $('.loading_spinner').last();
        var popup = $('#' + id + '-div');
        var saved = $('.page:visible', popup);
        $('.page', popup).show();
        var fields = $(
          '[class^=sample] input, [class^=sample] textarea, [class^=sample] select, .do_not_submit',
          popup
        )
          .not(':disabled')
          .attr('disabled', 'disabled');
        $(this)
          .hide()
          .after('<div class="popup_button wait_submit">Wait...</div>');
        $('form:not(.do_not_submit)', popup).submit();
        $('.page', popup).not(saved).hide();
        fields.removeAttr('disabled');
      }
    );
    $('#' + id + '-div form').bind('ajax:complete', function () {
      if (spinner_elem) {
        window.stop_loading_spinner(spinner_elem);
        $(this)
          .closest('.popup_window')
          .find('.outer > .popup_button_bar .popup_submit_button')
          .show()
          .siblings('.wait_submit')
          .remove();
      }
    });
    this.pull_buttons(id);
  };
  this.LightboxObject.prototype.pull_buttons = function (resource) {
    // Takes out buttons
    $('#' + resource + '-div .outer > .popup_button_bar').remove();
    var visibleButtons = $(
      '#' + resource + '-div .wrapper .popup_button_bar'
    ).not('.page:hidden *');
    visibleButtons
      .clone(true)
      .show()
      .appendTo($('#' + resource + '-div .outer'));
    visibleButtons.hide();
  };
  this.LightboxObject.prototype.close = function () {
    var $el = $('#' + this.id + '-div');
    $el.trigger('pmatrix.' + this.id + '.lightbox.close');
    $el.prev('.blackout').remove();
    $el.next('.loading_spinner').remove();
    $el.remove();
    window.current_lightboxes.pop();
    window.current_lightbox =
      window.current_lightboxes[window.current_lightboxes.length - 1] || null;
  };
  this.LightboxObject.prototype.close_previous = function () {
    var previous_lightbox =
      window.current_lightboxes[window.current_lightboxes.length - 2] || null;
    if (previous_lightbox != null) {
      var $el = $('#' + previous_lightbox.id + '-div');
      $el.trigger('pmatrix.' + previous_lightbox.id + '.lightbox.close');
      $el.prev('.blackout').remove();
      $el.next('.loading_spinner').remove();
      $el.remove();
      window.current_lightboxes.splice(window.current_lightboxes.length - 2, 1);
      var z_index =
        parseInt($('#' + window.current_lightbox.id + '-div').css('z-index')) -
        10;
      $('#' + window.current_lightbox.id + '-div')
        .prev('.blackout')
        .css('z-index', z_index - 1);
      $('#' + window.current_lightbox.id + '-div').css('z-index', z_index);
      $('#' + window.current_lightbox.id + '-div')
        .find('.wrapper')
        .css('z-index', z_index + 1);
      $('#' + window.current_lightbox.id + '-div')
        .next('.loading_spinner')
        .css('z-index', z_index + 2);
    }
  };
  this.LightboxObject.prototype.buttonClicks = function () {
    var id = this.id;
    var parent = this;
    var me = this;
    // Next button
    $('#' + id + '-div')
      .on('click continue', '.popup_next_button:not(.disabled)', function () {
        $('#' + id + '-div .popup_button_bar').show();
        $('.page:visible').hide().next('.page').show();
        parent.pull_buttons(id);
      })
      // Back button
      .on('click', '.popup_back_button:not(.disabled)', function () {
        $('#' + id + '-div .popup_button_bar').show();
        $('.page:visible').hide().prev('.page').show();
        parent.pull_buttons(id);
      })
      // Cancel or x button
      .on(
        'click',
        '.popup_cancel_button:not(.disabled), .x_button',
        function () {
          me.close();
        }
      )
      // Clear fields button
      .on('click', '.popup_clear_button:not(.disabled)', function () {
        clear_lease_fields();
      });
    // Pull all buttons no matter which of the four before you do?
    this.pull_buttons(id);
    // Focus on the date picker if it's on.
    $(
      '#' +
        id +
        "-div input:first[type='text']:not(.dpicker):not(.hasDatepicker)"
    ).focus();
    $('#' + id + '-div').trigger('popup_loaded');
  };
}).call(window.pmatrix.ui.lightbox);
window.start_lightbox = function (
  id,
  height,
  width,
  styled_lightbox,
  sync_input_visibility,
  use_default_drop_downs
) {
  window.current_lightbox = new window.pmatrix.ui.lightbox.LightboxObject();
  window.current_lightbox.create(
    id,
    height,
    width,
    styled_lightbox,
    sync_input_visibility,
    use_default_drop_downs
  );
  window.current_lightboxes.push(window.current_lightbox);
  lightbox_reinit_scroll_panes('#' + id + '-div');
};
